

<template>
  <div>
    <h1 class="text-center text-box">
      <b>{{ `${result.name} ${formatDate(lotto[0].date)}` }}</b>
    </h1>
    <div class="mb-4">
      <!-- 
      <p class="text-center">
        {{ `${result.sub_title}` }}
      </p> -->

      <table class="table table-sm table-bordered text-center">
        <tbody-2
          v-if="layout.status === 'START' || loading_start"
          :rewards="resultNumber"
          tag="start"
        />
        <tbody-2 :rewards="lotto[0].rewards" tag="wating" v-else />
      </table>
    </div>

    <div class="mt-5">
      <h1 class="text-center text-box">
        <b>{{ `Hà Nội ngôi sao: ${formatDate(minhngoc.date)}` }}</b>
      </h1>

      <div class="mb-4">
        <table class="table table-sm table-bordered text-center">
          <tbody-2 :rewards="minhngoc.rewards" tag="wating" />
        </table>
        <!-- <table-2 /> -->
      </div>
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Tbody2 from "./Tbody2.vue";

export default {
  components: { PulseLoader, Tbody2 },
  props: { result: Array, layout: Array, lotto: Array },
  computed: {
    hashtagsFilteredNotifications() {
      return this.notifications.filter((notification) => {
        return this.hashtags.some((hashtag) =>
          notification.hashtags.includes(hashtag)
        );
      });
    },
  },
  created() {
    for (var i = 0; i < 27; i++) {
      this.resultNumber.push({ id: i + 1, result: null });
    }
    this.sockets.subscribe(`lotto_7`, (data) => {
      this.resultNumber[this.filterId(data.id)].result = data.lotto;
    });

    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/minhngoc`)
      .then(({ data }) => {
        this.minhngoc = data;
      })
      .catch((err) => {
        return err;
      });

    this.sockets.subscribe(`start_7`, () => {
      this.loading_start = true;
      this.loading_reward = false;
    });

    this.sockets.subscribe(`reward_7`, (data) => {
      this.resultNumber[this.filterId(data.id)].result = data.lotto;
    });
  },
  data() {
    return {
      loading_start: false,
      loading_reward: false,
      data: [],
      tag: [],

      minhngoc: [],
      resultNumber: [],
      color: "#000",
      size: "6px",
    };
  },
  methods: {
    formatDate(val) {
      let date =
        this.layout.status === "START" ? new Date(Date.now()) : new Date(val);
      date = new Date(date.getTime() - 7 * 60 * 60 * 1000).toString();
      return `${
        new Date(date).getDate().toString().length != 1
          ? new Date(date).getDate()
          : "0" + new Date(date).getDate()
      }/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`;
    },

    filterId(number) {
      const index = this.resultNumber.findIndex((element) => {
        if (element.id === number) {
          return true;
        }
      });
      return index;
    },
  },
};
</script>
