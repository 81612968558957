

<template>
  <div>
    <tbody>
      <tr>
        <th scope="row">Đặc biệt</th>
        <td colspan="12">
          <h1 v-html="showResultNumber(27)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(27) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải nhất</th>
        <td colspan="12">
          <h2 v-html="showResultNumber(1)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(1) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải nhì</th>
        <td colspan="6">
          <div v-html="showResultNumber(2)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(2) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="6">
          <div v-html="showResultNumber(3)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(3) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row" rowspan="2">Giải ba</th>
        <td colspan="4">
          <div v-html="showResultNumber(4)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(4) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(5)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(5) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(6)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(6) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <div v-html="showResultNumber(7)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(7) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(8)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(8) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(9)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(9) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải nhì</th>
        <td colspan="3">
          <div v-html="showResultNumber(10)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(10) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <div v-html="showResultNumber(11)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(11) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <div v-html="showResultNumber(12)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(12) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <div v-html="showResultNumber(13)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(13) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row" rowspan="2">Giải ba</th>
        <td colspan="4">
          <div v-html="showResultNumber(14)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(14) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(15)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(15) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(16)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(16) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <td colspan="4">
          <div v-html="showResultNumber(17)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(17) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(18)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(18) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(19)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(19) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải ba</th>
        <td colspan="4">
          <div v-html="showResultNumber(20)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(20) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(21)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(21) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="4">
          <div v-html="showResultNumber(22)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(22) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
      <tr>
        <th scope="row">Giải ba</th>
        <td colspan="3">
          <h3 v-html="showResultNumber(23)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(23) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <h3 v-html="showResultNumber(24)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(24) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <h3 v-html="showResultNumber(25)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(25) === null && tag === 'start'"
          ></pulse-loader>
        </td>
        <td colspan="3">
          <h3 v-html="showResultNumber(26)" />
          <pulse-loader
            :color="color"
            :size="size"
            v-if="showResultNumber(26) === null && tag === 'start'"
          ></pulse-loader>
        </td>
      </tr>
    </tbody>
    <table-footer @filterNumber="filterNumber" @digitNumber="digitNumber" />
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import TableFooter from "./TableFooter.vue";
export default {
  components: { PulseLoader, TableFooter },
  props: {
    rewards: Array,
    tag: String,
  },
  computed: {},
  created() {},
  data() {
    return {
      color: "#000",
      size: "6px",
      filter: null,
      digit: null,
    };
  },
  methods: {
    showResultNumber(id) {
      let number = [];
      let showNumber = "";
      let cutNumber = "";
      let color;
      let size;

      for (var i = 0; i < this.rewards.length; i++) {
        if (this.tag === "start") {
          if (this.rewards[i].id === id) {
            number = this.rewards[i].result;
            return number;
          }
        }
        if (this.tag === "wating") {
          if (this.rewards[i].showNo === id) {
            for (var j = 0; j < this.rewards[i].value.length; j++) {
              number.push(this.rewards[i].value.split("")[j]);
            }
          }
        }
      }

      for (var k = 0; k < number.length; k++) {
        showNumber += number[k];
      }

      const second = parseInt(showNumber[number.length - 2]);
      const last = parseInt(showNumber[number.length - 1]);
      if (this.digit === 2) showNumber = second.toString() + last.toString();
      if (this.digit === 3)
        showNumber =
          showNumber[number.length - 3] != null
            ? showNumber[number.length - 3] +
              second.toString() +
              last.toString()
            : "" + second.toString() + last.toString();

      //SETTING CSS
      if (last === this.filter) {
        color = 1;
      }
      if (second === this.filter) {
        color = 2;
      }
      if (last === this.filter && second === this.filter) {
        color = 3;
      }
      if (
        id === 27 ||
        id === 1 ||
        id === 23 ||
        id === 24 ||
        id === 25 ||
        id === 26
      ) {
        size = "lg";
      } else {
        size = "md";
      }
      //SHOW NUMBER
      if (last === this.filter || second === this.filter) {
        cutNumber = second.toString() + last.toString();
        showNumber = [];
        for (var l = 0; l < number.length - 2; l++) {
          showNumber += number[l];
        }
        return `<div class="d-flex justify-content-center">
                  <div class="row"><p class="m-0 pt-1">${
                    this.digit != 2
                      ? this.digit === 3
                        ? showNumber[number.length - 3] != null
                          ? showNumber[number.length - 3]
                          : ""
                        : showNumber
                      : ""
                  }</p>
                    <p class="hilight-${color} hi-${size}">${cutNumber}</p>
                  </div>
                </div>`;
      } else {
        return `<div>${showNumber}</div>`;
      }
    },

    filterNumber(data) {
      this.filter = data;
    },
    digitNumber(data) {
      this.digit = data;
    },
  },
};
</script>
<style>
.hi-lg {
  width: 46px;
  font-size: 38px;
}
.hi-md {
  width: 32px;
  font-size: 21px;
}
.hilight-1 {
  border-radius: 24px;
  background-color: rgb(0, 160, 40);
  color: white;
  margin: 0;
}
.hilight-2 {
  border-radius: 24px;
  background-color: rgb(0, 11, 73);
  color: white;
  margin: 0;
}
.hilight-3 {
  border-radius: 24px;
  background-color: rgb(230, 170, 6);
  color: white;
  margin: 0;
}
</style>
<style scoped>
h1 {
  font-size: 34px;
  font-weight: bold;
  color: maroon;
}
h2 {
  font-size: 28px;
  font-weight: bold;
}
h3 {
  font-size: 28px;
  font-weight: bold;
  color: maroon;
}

th {
  width: 6% !important;
}
td {
  width: 12% !important;
  font-size: 18px;
  font-weight: bold;
}
</style>
