<template>
  <header>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light hidden-pc p-0">
      <div
        class="container-fluid"
        style="
          z-index: 99;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-color: #000c50;
        "
      >
        <b-nav>
          <b-nav-item active><a>TRỰC TIẾP</a></b-nav-item>
          <b-nav-item>SỔ KẾT QUẢ </b-nav-item>
          <b-nav-item>SỚ ĐẦU ĐUÔI</b-nav-item>
          <b-nav-item> MIỀN NAM</b-nav-item>
          <b-nav-item>MIỀN TRUNG</b-nav-item>
          <b-nav-item>MIỀN BẮC & ĐIỆN TOÁN</b-nav-item>
        </b-nav>
      </div>
    </nav>

    <!-- Navbar -->
  </header>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";

export default {
  props: { name: String },
  computed: {},
  components: {},
  created() {
    // console.log('created', this.$store.state.curLanguage);
    Vue.i18n.set(this.$store.state.curLanguage.short);
    // this.getProfile();
  },
  name: "app",
  data() {
    return { selectedLanguage: this.$store.state.curLanguage };
  },

  watch: {
    selectedLanguage: function (newLang) {
      // console.log('new lang selected', newLang);
      Vue.i18n.set(newLang.short);
      this.$store.commit("setLanguage", newLang.short);
    },
  },
  methods: {},
};
</script>
<style  scoped>
a {
  color: rgb(255, 255, 255);
}
a:hover {
  color: rgb(170, 170, 170);
}
.mobile-menu {
  display: flex;
  position: absolute;
  z-index: 20;
}
.head-mobile {
  background: linear-gradient(
    90deg,
    rgba(255, 136, 0, 0.308) 0%,
    rgba(255, 136, 0, 0.075) 100%
  );
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.test {
  background-color: rgba(255, 197, 110, 0.6);

  background: linear-gradient(
    90deg,
    rgba(255, 197, 110) 0%,
    rgba(255, 197, 110, 0.6) 100%
  );
}
.b-nav {
  color: white;
}
</style>