<template>
  <div>
    <img :src="data[0].banner" width="100%" v-if="data[0].banner != null" />

    <b-input-group prepend="Thông báo" class="mb-4 mt-2 mr-sm-2 mb-sm-0">
      <b-form-input
        id="inline-form-input-username"
        style="background-color: white; color: black"
        :placeholder="announce[0]"
        readonly
      ></b-form-input>
    </b-input-group>
    <div class="row mt-2">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <Sidebar :lotto_id="lotto[0]._id" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
        <div v-for="(val, i) in data" :key="i" class="card">
          <Table :result="val" :layout="data[0]" :lotto="lotto" />
          <!-- <Table2 :result="val" /> -->
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import "bootstrap/dist/css/bootstrap.min.css";

import Table from "./component/Table.vue";

import Sidebar from "./component/Sidebar.vue";
import Footer from "./Footer.vue";
export default {
  components: { Table, Sidebar, Footer },
  props: {},
  computed: {},
  created() {
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/lotto-reward/7`)
      .then(({ data }) => {
        this.lotto = data;
      })
      .catch((err) => {
        return err;
      });
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/lotto/7`)
      .then(({ data }) => {
        this.data = data;
      })
      .catch((err) => {
        return err;
      });
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/announce`)
      .then(({ data }) => {
        this.announce = data;
      })
      .catch((err) => {
        return err;
      });
  },
  data() {
    return {
      data: [],
      announce: null,
      lotto: null,
    };
  },
  methods: {},
};
</script>
<style>
</style>
