
    <template>
  <table width="100%">
    <tbody>
      <tr>
        <td>
          <button :class="['bt p-2', digit === null ? 'bt-active' : '']">
            <p class="m-0" @click="digitNumber(null)">normal</p>
          </button>
        </td>
        <td>
          <button :class="['bt p-2', digit === 2 ? 'bt-active' : '']">
            <p class="m-0" @click="digitNumber(2)">2 số</p>
          </button>
        </td>
        <td>
          <button :class="['bt p-2', digit === 3 ? 'bt-active' : '']">
            <p class="m-0" @click="digitNumber(3)">3 Số</p>
          </button>
        </td>
        <td>
          <div>
            <button
              :class="['m-2', value === i ? 'circle-active' : 'circle']"
              v-for="(n, i) in 10"
              :key="i"
              @click="filterNumber(i)"
            >
              <p class="m-1">{{ i }}</p>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  components: {},
  props: {},
  computed: {},
  mounted() {},
  data() {
    return {
      value: null,
      value_temp: null,
      digit: null,
    };
  },
  methods: {
    filterNumber(val) {
      this.value != val ? (this.value = val) : (this.value = null);
      this.$emit("filterNumber", this.value);
    },
    digitNumber(val) {
      this.digit = val;
      this.$emit("digitNumber", val);
    },
  },
};
</script>
<style>
.circle {
  border-radius: 24px;
  background-color: rgb(0, 11, 73);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
.circle-active {
  border-radius: 24px;
  background-color: rgb(255, 166, 0);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}
.bt {
  background: #fff;
  border-radius: 5px;
  border: rgb(197, 197, 197) solid 1px;
}
.bt-active {
  background: rgb(255, 166, 0);
}
</style>
