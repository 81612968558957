<template>
  <div>
    <table class="table table-sm table-bordered">
      <b-calendar
        block
        class="mb-2"
        v-model="value"
        @context="onContext"
        locale="en-US"
      ></b-calendar>
      <tbody>
        <tr>
          <th scope="row" class="text-box">
            <p style="font-size: 14px" class="m-0">
              TƯỜNG THUẬT TRỰC TIẾP XỔ SỐ
            </p>
          </th>
        </tr>
        <tr v-for="(val, i) in tab1" :key="i">
          <th scope="row">
            <div class="d-flex justify-content-between">
              <a class="m-0">{{ val }}</a>
              <pulse-loader :color="color" :size="size"></pulse-loader>
            </div>
          </th>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th scope="row" class="text-box">
            <p style="font-size: 14px" class="m-0">KẾT QUẢ XỔ SỐ HÔM NAY</p>
          </th>
        </tr>
        <b class="m-1">Kết quả xổ Số Miền Nam</b>
        <tr v-for="(val, i) in tab2" :key="i">
          <th scope="row">
            <div class="d-flex justify-content-between">
              <a class="m-0">{{ val }}</a>
              <pulse-loader :color="color" :size="size"></pulse-loader>
            </div>
          </th>
        </tr>
        <b class="m-1">Kết quả xổ Số Miền Bắc</b>
        <tr v-for="(val, i) in tab3" :key="i">
          <th scope="row">
            <div class="d-flex justify-content-between">
              <a class="m-0">{{ val }}</a>
              <pulse-loader :color="color" :size="size"></pulse-loader>
            </div>
          </th>
        </tr>
        <b class="m-1">Kết quả xổ Số Miền Trung</b>
        <tr v-for="(val, i) in tab4" :key="i">
          <th scope="row">
            <div class="d-flex justify-content-between">
              <a class="m-0">{{ val }}</a>
              <pulse-loader :color="color" :size="size"></pulse-loader>
            </div>
          </th>
        </tr>
        <b class="m-1">Kết quả xổ Số Điện Toán </b>
        <tr v-for="(val, i) in tab5" :key="i">
          <th scope="row">
            <div class="d-flex justify-content-between">
              <a class="m-0">{{ val }}</a>
              <pulse-loader :color="color" :size="size"></pulse-loader>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  components: { PulseLoader },
  props: {},
  computed: {},
  mounted() {},
  data() {
    return {
      value: "",
      context: null,
      color: "#bec8ff",
      size: "6px",
      tab1: [
        "Trực Tiếp Xổ Số Miền Nam",
        "Trực Tiếp Xổ Số Miền Bắc",
        "Trực Tiếp Xổ Số Miền Trung",
        "Trực Tiếp Xổ Số Vietlott",
      ],
      tab2: [
        "Kết quả xổ Số Cần Thơ",
        "Kết quả xổ Số Đồng Nai",
        "Kết quả xổ Số Sóc Trăng",
      ],
      tab3: ["Kết quả xổ Số Bắc Ninh"],
      tab4: ["Kết quả xổ Số Đà Nẵng", "Kết quả xổ Số Khánh Hòa"],
      tab5: [
        "Kết quả xổ Số 1*2*3",
        "Kết quả xổ Số 6X36",
        "Kết quả xổ Số Thần Tài 4",
        "Kết quả xổ Số Mega 6/45",
      ],
    };
  },
  methods: {
    onContext(ctx) {
      this.context = ctx;
    },
  },
};
</script>
<style scoped>
a {
  color: #687fff;
  font-size: 12px;
  cursor: pointer;
}
b {
  color: #bec8ff;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
</style>
